.address-search-bar {
    display: flex;
    /* flex:1; */
    width:100%;
    align-items: center;
    margin-top: 6%;
    margin-bottom: 6%;
}
.address-search-bar-form {
    display: flex;
    width:100%;
    /* flex-direction: column; */
}
.address-search-bar-label {
    display:flex;
    width:100%;
}

.address-search-bar-input {

    display: flex;
    /* flex:1; */
    width:100%;
    align-items: center;
    line-height: 25px;
}

.dropdown-parent-container {
    display: flex;
    justify-content: center; /* Horizontally center the image */
    flex-direction: column;
}

.addy-gen-blurb {
    display: flex;
    justify-content: center; /* Horizontally center the image */
}
.addy-gen-alternate-views-button {
    display: flex;
    justify-content: center;
}
.create-photos-textarea {
    width: 90%;
    height: 80px;
    resize: none;
    border-radius: 5px;
    padding: 10px;
    margin: auto;
}