.landscape-gallery-container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .gallery-content {
    display: flex;
    gap: 20px;
    transition: transform 0.5s;
  }
  
  .landscape-gallery {
    display: flex;
    gap: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f7f7f7;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
  
  .design-option {
    flex: 0 0 auto;
    width: 300px;
    cursor: pointer;
    text-align: center;
    border: 2px solid transparent;
    border-radius: 8px;
    transition: transform 0.3s;
  }
  
  .design-option:hover,
  .design-option.selected {
    transform: translateY(-5px);
    border: 4px solid #444;
  }
  
  .design-option img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 6px;
  }
  
  .style-name {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
  }
  
  .dot-navigation {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .active {
    background-color: #007bff;
  }
  