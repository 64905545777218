.status-page {
    display: flex;
    /* flex:1; */
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 20;
}

.status-header {
    display: flex;
    flex:1;
    font-size: large;
}

.current-status-header {
    display: flex;
}

.availability-status {
    color: green;
}