/* styles.css */
.image-gallery {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: space-between; /* Distribute items evenly along the main axis */
}

.gallery-item {
  position: relative; /* Required for absolute positioning of the download button */
  width: calc(33.33% - 8px); /* Adjust the width of each item based on your grid configuration */
  margin-bottom: 16px; /* Adjust margin as needed */
}

.gallery-item img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
}

.download-button-container {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better visibility */
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 4px; /* Add border radius for rounded corners */
}

.download-button {
  color: rgba(255, 255, 255, 0.8); /* Slightly transparent white color */
  background-color: transparent; /* Transparent background */
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s; /* Add transition effect for smooth color change */
}

.download-button:hover {
  background-color: #007bff; /* Bright blue color on hover */
}

/* Optional: Add hover effect for download icon */
.download-icon.highlight {
  color: yellow; /* Change color on hover */
}
