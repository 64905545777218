.mask-editor {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    margin-bottom: 3%;
  }

.mask-editor-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #2e3238;
    align-items: center;
    border: 1px dashed #2e3238;
    color: white;
    font-weight: bold;
}

.toolbar {
    display: flex;
    flex:1;
    align-self: flex-start;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #454b54;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 2%;
    margin-left: 3%;
    border-radius: 20px;
}

.canvas-container {
    margin-top: 4%;
    margin-bottom: 5%;
}
.mask-subtitle {
    display:flex;
    align-items: flex-start;
    width: 100%;
}

.mask-download-button-container {
    display: flex;
    /* background-color: white; */
    margin:2%;
    align-items: flex-end;
    justify-content: flex-end;
}

.mask-download-button {
    display: flex;
    background-color: #454b54;
    border-radius: 15px;
    border: none;
    color:#e3e4e8;
    /* color: white; */
}

