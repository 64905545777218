/* LoginForm.css */
.login-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .login-modal-content label {
    display: block;
    margin-bottom: 10px;
  }
  
  .login-modal-content input {
    /* width: 100%; */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
  }
  
  .login-modal-content .error-message {
    color: red;
  }
  
  .login-modal-content button {
    background-color: #4caf50;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-modal-content button:hover {
    background-color: #45a049;
  }
  