.buy-credits-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buy-credits-modal-container {
    display: flex;
    position: relative; /* Set container to relative positioning */
    flex-direction: row;
    align-items:flex-start; 
    justify-content: center;
    width: 85%;
    height: 52%;
    overflow: auto;
    background-color: white;
    padding: 1px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

.buy-credits-plan {
  /* padding-top: 10%; */
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  /* width: 85%; */
  flex: 1;
  height: 100%;
  /* padding: 10px; */
  /* margin: 10px; */
  border: 1px solid #ccc;
  /* border-radius: 8px; */
  text-align: center;
}

.buy-credits-plan-contents {
  justify-content: flex-start;
  align-items:flex-start;
  height: 80%;
}

.buy-credits-plan-title {
  display: flex;
  justify-content: center;
  /* margin-top: 20%; */
  /* flex:1; */
  /* height:20%; */
  font-size: 22px;
  margin-bottom: 8px;
}

.buy-credits-plan-info {
  font-size: 14px;
  margin-bottom: 4px;
}

.buy-credits-plan-price {
  font-size: 16px;
  margin-bottom: 12px;
}

.buy-credits-plan-button {
  background-color: #0071e4;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.buy-credits-plan-button:hover {
  background-color: #0056b3;
}

.buy-credits-modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

/* .buy-credits-subscribe-button,
.buy-credits-close-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
} */

.buy-credits-subscribe-button:hover,
.buy-credits-close-button:hover {
  background-color: #0056b3;
  /* background-color: #007bff; */
  border-radius: 25%; /* Add rounded corners */
}

.buy-credits-close-button {
  position: absolute; /* Set close button to absolute positioning */
  top: 10px; /* Adjust top position */
  right: 10px; /* Adjust right position */
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  z-index: 1;
}

.buy-credits-home-button {
  display:flex;
  width:100%;
  background-color: #0071e4;
  color: white;
  cursor: pointer;
  border: none;
  justify-content: center;
  border-radius: 20px;
  padding: 8px 16px; /* Adjust the padding as needed */
  /* margin-left: 10px; Adjust the margin as needed */
  font-size: 17px;
  margin-bottom: 20px;
}

.buy-credits-home-button:hover {
  background-color: #0056b3;
}