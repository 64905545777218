.homepage {
  width: 100%; /* Make the entire page use the full width of the browser container */
  max-width: none; /* Remove the maximum width limit */
}

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #f5f5f5;
  }

  nav a {
    margin-left: 16px;
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
  
  nav a:hover {
    text-decoration: underline;
  }
  
  main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 32px;
    /* width: 100%; /* Make the entire page use the full width of the browser container */
    /* max-width: none;  */
  }
  
  h1, h2 {
    margin-top: 0;
  }
  
  .content-links {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 32px;
  }
  
  .content-links a {
    display: block;
    padding: 16px;
    background-color: #f5f5f5;
    text-decoration: none;
    color: #333;
    font-weight: bold;
    border-radius: 8px;
  }
  
  .content-links a:hover {
    background-color: #333;
    color: #f5f5f5;
  }
  

  .email-button {
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    background-color: rgb(245, 245, 245);
    margin-top: 15px;
  }

  .email-button a {
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    width: '100%';
    padding: '10px';
    border-radius: '5px';
    color: 'rgb(51, 51, 51)';
    background-color: 'rgb(245, 245, 245)';
  }

  .email-button a:hover {
    background-color: #333;
    color: #f5f5f5;
  }

  /* Add this CSS to your component's stylesheet or a global stylesheet */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the top */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-top: 100px; /* Adjust this value to control the distance from the top */
  max-width: 400px; /* You can set a maximum width for the modal content if desired */
  position: relative; /* Required for positioning the close button */
}

.message {
  margin-bottom: 40px; /* Add vertical spacing between the message and the close button */
}
/* Style the close button as needed */

.close-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #434343; /* Red background color for the close button */
  color: #fff; /* White text color for the close button */
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

/* Style the close button as needed */
.close-button:hover {
  background-color: #1100cc; /* Darker red background on hover */
}

.email-button:hover {
  color: rgb(51, 51, 51);
  background-color: rgb(245, 245, 245);
}

@keyframes gradientTransition {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.hover-email-button {
  width: 100%;
  padding: 10px;
  text-decoration: none;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  border-radius: 8px;
}

.hover-email-button:hover {
  background: #ccc;
}

.gradient-button {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(90deg, #FF6B6B, #FFE66D, #8FD3F4);
  background-size: 200% 200%;
  animation: gradientTransition 4s ease infinite; /* Adjust the duration as needed */
  border: none;
  /* color: #fff; */
  /* width: '100%',
                  padding: '10px',
                  textDecoration: 'none',
                  color: '#333',
                  fontSize: '16px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  borderRadius: '8px' */
  /* transition: background-color 0.3s, color 0.3s; Add transition for smooth hover effect */
}

.image-upload-container {
  /* display: flex; */
}

.create-photos-text {
  display: flex;
}

.generate-image-via-text-button {
  background-color: #0071e4;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 8px 16px; /* Adjust the padding as needed */
  margin-left: 10px; /* Adjust the margin as needed */
  font-size: 16px;
}

.generate-image-via-text-button:hover {
  background-color: #0056b3;
}

/* .dark-theme {
  
} */