/* PasswordReset.css */
.password-reset-page {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .password-reset-page input {
    margin-bottom: 10px;
    padding: 8px;
  }
  
  .password-reset-page button {
    padding: 8px 16px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  