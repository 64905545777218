/* body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
    color: #333;
  }

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #f5f5f5;
}

.home-ul nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  nav a {
    margin-left: 16px;
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
  


  nav a:hover {
    text-decoration: underline;
  }

  .home-main {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 1em;
  }

  footer {
    background-color: #333;
    color: #fff;
    padding: 1em;
    text-align: center;
  } */

  .home-ul nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  /* nav a {
    text-decoration: none;
  } */
  .home-main {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 1em;
  }

  .home-link {
    text-decoration: none;
  }
  .home {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .featured {
    flex: 2;
    background-color: #f0f0f0;
    padding: 1em;
  }
  
  .sidebar {
    flex: 1;
    background-color: #f0f0f0;
    padding: 1em;
    flex-direction: column;
    align-items: flex-end; /* Align items to the end of the flex container */
  }

.credits-summary {
    display: flex;
    align-items: center;
    margin: 0;
    margin-right: 2.5em; /* Add some spacing between "Credits Available" and the value */
}

.user-gen-images-section {
  /* display: flex; */
  margin-top: 3em;
}

.home-sidebar-imgs {
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column; 
  width: 100%;
  align-items: center;
}

.home-sidebar-imgs img {
  width: 90%;
  /* height: 100%; Set height to 100% */
  object-fit: cover;
  aspect-ratio: 1/1; /* Maintain a square aspect ratio */
  margin-bottom: 10px;
}
.image-upload-failure-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload-failure-modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-upload-failure-modal-close {
  align-self: flex-end;
  cursor: pointer;
  font-size: 20px;
  color: #333;
  background: none;
  border: none;
  padding: 0;
}

.image-upload-failure-modal-close:hover {
  color: #f00;
}

.image-upload-failure-modal-message {
  margin-bottom: 10px;
}

.image-upload-failure-modal-link {
  color: #007bff; /* Link color */
  text-decoration: none;
  display: block; /* Make it a block element to occupy full width */
  text-align: center; /* Center the link */
}

.image-upload-failure-modal-link:hover {
  text-decoration: underline; /* Underline on hover */
}


.create-mode-scroller {
  display: flex;
  flex:1;
  height: 2.82353rem;
  background: white;
  border-radius: 90px;
}

.create-mode-button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background: white;
  border: none;
  /* background: #1d1d1f; */
  /* color: #f5f5f7; */
  /* color: black; */
  color: #1d1d1f;
  cursor: default;
  font-size: 15px;
  font-weight: 600;
}

.create-mode-button.selected {
  background-color: #007bff;
  color: white;
}

.colors {
  --surface-0: #000;
  --surface-5: #0c0c0e;
  --surface-10: #17181c;
  --surface-15: #23252a;
  --surface-20: #2e3138;
  --surface-30: #464953;
  --surface-40: #5d616f;
  --surface-50: #747a8b;
  --surface-60: #9094a2;
  --surface-70: #acafb9;
  --surface-80: #c7cad1;
  --surface-90: #e3e4e8;
  --surface-100: #fff;
  --primary-0: #000;
  --primary-10: #17191c;
  --primary-15: #22252a;
  --primary-20: #2e3238;
  --primary-25: #393e46;
  --primary-30: #454b54;
  --primary-40: #5c6470;
  --primary-50: #737d8c;
  --primary-60: #8f97a3;
  --primary-70: #abb1ba;
  --primary-80: #c7cbd1;
  --primary-90: #e3e5e8;
}