
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container {
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;

    /* max-width: 80%; */
    /* max-height: 80%; */
    width: 85%;
    height: 52%;
    overflow: auto;
    background-color: white;
    padding: 1px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .plan {
    padding-top: 10%;
    /* display: flex; */
    /* flex-direction: row; */
    align-items: center; 
    justify-content: center;
    /* width: 85%; */
    flex: 1;
    height: 100%;
    /* padding: 10px; */
    /* margin: 10px; */
    border: 1px solid #ccc;
    /* border-radius: 8px; */
    text-align: center;

    /* margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px; */
  }
  
  .plan-title {
    /* margin-top: 20%; */
    flex:1;
    /* height:20%; */
    font-size: 18px;
    margin-bottom: 8px;
  }
  
  .plan-info {
    font-size: 14px;
    margin-bottom: 4px;
  }
  
  .plan-price {
    font-size: 16px;
    margin-bottom: 12px;
  }
  
  .plan-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .plan-button:hover {
    background-color: #0056b3;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .subscribe-button,
  .close-button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .subscribe-button:hover,
  .close-button:hover {
    background-color: #555;
  }