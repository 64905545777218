/* Add this CSS to your stylesheet */
.dropdown-screen {
    display:flex;
    justify-content: center; /* Horizontally center the image */
    flex-direction: column;
    align-items: center; /* Center items vertically */
}

.custom-dropdown {
    font-size: 16px;
    padding: 8px; /* Adjust padding as needed */
    display: flex;
    justify-self: center;
    justify-content: space-between; /* Horizontally center the image */
    /* display: inline-block; Make the dropdown inline-block */
    flex-grow: 0; /* Prevent the dropdown from growing */
    max-width: max-content; /* Limit the width to the widest option text */

    /* width:auto; */
    /* max-width: 100%; Ensure the image fits within the container */

  }
  
  .selected-address {
    font-size: 24px; /* Adjust font size as needed */
    padding: 10px; /* Adjust padding as needed */
  }

  .selected-image-container {
    margin-top: 20px;
    display: flex;
    justify-content: center; /* Horizontally center the image */
  }
  
  .selected-image {
    max-width: 100%; /* Ensure the image fits within the container */
    height: auto; /* Maintain aspect ratio */
  }
  