.progress-bar-container {
    width: 100%;
    height: 20px;
    border: 1px solid #000; /* Border style */
    border-radius: 10px; /* Rounded edges */
    overflow: hidden; /* Ensure rounded corners are visible */
  }
  
  .progress-bar {
    height: 100%;
    width: 0;
    background-color: transparent;
    position: relative;
  }
  
  .progress-bar-fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(135deg, #007bff 25%, transparent 25%, transparent 50%, #007bff 50%, #007bff 75%, transparent 75%, transparent);
    background-size: 10px 10px;
  }
  