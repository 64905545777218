/* AuthModal.css */
.auth-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .auth-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 400px; /* Increase the width of the modal */
  }
  
  .auth-modal input {
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px; /* Increase the font size for better readability */
  }
  
  .auth-modal button {
    padding: 8px 16px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .password-reset-link {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
  }
  
  .auth-modal .error-message {
    color: red;
    margin-bottom: 10px;
  }