.image-upload {
    padding: 0;
    border: 1px dashed #aaa;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 100%; */
    height: 100%;
    border-radius: 8px;
    box-sizing: border-box; /* Add box-sizing to include border width */
    /* margin-right: 8px; Add margin to the right side */
  }

  .drag-drop-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    flex-grow: 1;
    margin: 0;
  }
  
  .drag-drop-area {
    padding: 17px; /* Adjust padding to account for the border width */
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Include border width in total width */
  }
  
  .drag-drop-area:hover {
    background-color: #f0f0f0;
  }
  
  .drag-drop-area p {
    margin: 0;
    font-size: 16px;
    color: #555;
  }
  
  .upload-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .upload-button:hover {
    background-color: #0056b3;
  }
  
  /* .image-upload-container {
    display:flex;
    justify-content:center;
  }

  .create-photos-text {
    display: flex;
    justify-content:center;
  } */