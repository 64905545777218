.prompt-list {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  
  .prompt-item {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 8px;
    margin-bottom: 5px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    list-style-type: none;
  }
  
  .prompt-item:hover {
    /* background-color: #c7cad1; */
    background-color: gray;
    border-radius: 10px;
    --surface-0: #000;
    --surface-5: #0c0c0e;
    --surface-10: #17181c;
    --surface-15: #23252a;
    --surface-20: #2e3138;
    --surface-30: #464953;
    --surface-40: #5d616f;
    --surface-50: #747a8b;
    --surface-60: #9094a2;
    --surface-70: #acafb9;
    --surface-80: #c7cad1;
    --surface-90: #e3e4e8;
    --surface-100: #fff;
    --primary-0: #000;
    --primary-10: #17191c;
    --primary-15: #22252a;
    --primary-20: #2e3238;
    --primary-25: #393e46;
    --primary-30: #454b54;
    --primary-40: #5c6470;
    --primary-50: #737d8c;
    --primary-60: #8f97a3;
    --primary-70: #abb1ba;
    --primary-80: #c7cbd1;
    --primary-90: #e3e5e8;
  }

/* PhotoHistory.css */
.history-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85); /* Semi-transparent black background */
    display: flex;
    /* flex:1; */
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }
  
  .history-modal-close {
    align-self: flex-end;
    /* background-color: rgba(50,50,50,.88); */
    /* background-color: #fff; */
    background-color: #88888e;
    /* background-color: #007bff; Blue button color */
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }

  .history-modal {
    display:flex;
    width: 60%; /* Adjust the width as needed */
    /* max-width: 400px; Set maximum width if necessary */
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
    /* align-items: center; */
    /* align-self: center; */
    justify-content: center;
    flex-direction: column;
  }
  
  .history-modal img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .history-modal p {
    margin-bottom: 10px;
  }
  
  .history-modal a {
    display: block;
    color: blue;
    text-decoration: none;
    margin-bottom: 10px;
  }
  
  /* .history-modal button {
    background-color: #007bff; 
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  } */
  
  .history-modal button:hover {
    background-color: #0056b3; /* Darker blue color on hover */
  }
  
  .history-modal-download-button {
    background-color: #007bff; 
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 3%;
    font-size: larger;
  }