.image-upload-container-fake {
    /* background-color: #fff; */
    display: flex;
    flex-direction: column;
    margin:5px;
    margin-left: 13px;
    width: 90%;
    height: 100%;
    align-items: center;
    /* display: 'flex', flexDirection: 'column', height: '100%' */
  }
  
  .image-upload {
      padding: 0;
      border: 1px dashed #aaa;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* width: 100%; */
      height: 100%;
      border-radius: 8px;
      box-sizing: border-box; /* Add box-sizing to include border width */
      /* background-color: #fff; */
      background-color: #f0f0f0;
      cursor: pointer;
      /* margin-right: 8px; Add margin to the right side */
    }
    .image-upload:hover {
      background-color: #d5d4d4;
      /* background-color: #fff; */
    }
  
    .drag-drop-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
      flex-grow: 1;
      margin: 0;
    }
    
    .drag-drop-area {
      padding: 17px; /* Adjust padding to account for the border width */
      width: 100%; /* Ensure full width */
      box-sizing: border-box; /* Include border width in total width */
    }
    
    .drag-drop-area:hover {
      /* background-color: #f0f0f0; */
      background-color: #d5d4d4;
  
    }
    
    .drag-drop-area p {
      margin: 0;
      font-size: 16px;
      color: #555;
    }

    .upload-button {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      margin-top: 10px;
    }
    
    .upload-button:hover {
      background-color: #0056b3;
    }
  
    .size-container {
        display: flex;
        /* justify-content: space-around; */
        /* align-items: flex-end; */
        margin-bottom: 2em;
    }

    .size-buttons-container {
        display: flex;
        flex: 1;
        gap: 10px;
        align-items: center;
        /* justify-content: space-around; */
        margin-left: 1em;
        margin-right: auto;
      }
      
      .size-button {
        border: none;
        border-radius: 5px;
        background-color: lightslategrey;
        color: white;
        padding: 8px 12px;
        cursor: pointer;
      }
      
      .size-button.selected {
        background-color: darkgreen;
      }

      .text-prompt-and-button-container {
        display: flex;
        width: 85%;
      }

      .text-input-fake {
        display: flex;
        width: 100%;
        height: 100%;
      }

      .generate-image-via-text-button-fake {
        background-color: #007bff;
        color: white;
        cursor: pointer;
        border: none;
        border-radius: 4px;
        padding: 8px 16px; /* Adjust the padding as needed */
        /* margin-left: 10px; Adjust the margin as needed */
        font-size: 16px;
      }
      .generate-image-via-text-button-fake:hover {
        background-color: #0056b3;
      }